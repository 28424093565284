import { useState, useEffect } from 'react';
import ModelService from 'http/services/ModelService';
import Model from 'models/Model';

type Res = {
  isLoading: boolean;
  models: Model[];
};

export default function useModelList(models: Model[]): Res {
  const [isLoading, setIsLoading] = useState(false);
  const [onlineModels, setOnlineModels] = useState(models);

  useEffect(() => {

    async function fetchOnlineModels() {
      setIsLoading(true);

      try {
        const onlineModelsRes = await ModelService.getOnlineModels();

        // Sometimes CB explodes :)
        if (onlineModelsRes) {
          const nextModels = onlineModels.map(m => {
            const onlineModel = onlineModelsRes.find(oM => oM.username === m.username);
            const isOnline = onlineModel !== undefined;
            const currentShow = isOnline ? onlineModel!.currentShow : undefined;

            return ({
              ...m,
              isOnline,
              currentShow,
            });
          });

          setOnlineModels(nextModels);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchOnlineModels();
  }, []);

  return ({
    models: onlineModels.filter(m => m.isOnline === true),
    isLoading
  });
}
