import HttpClient from 'http/HttpClient';
import Model from 'models/Model';

export default class ModelService {
  static async getOnlineModels(): Promise<Model[]> {
    const { data } = await HttpClient.get(`/chaturbate-rooms-online`);
    return data.models;
  }

  static async isModelOnline(username: string): Promise<Model> {
    const { data } = await HttpClient.get(`/chaturbate-model-online?username=${username}`);
    return data.model;
  }
}
