import * as React from 'react';
import GenericUtils from 'utils/GenericUtils';

type Props = {
  position: 'relative' | 'absolute' | 'fixed';
  message?: string;
  className?: string;
};

export default function LoadingView(props: Props) {
  const gif = Math.floor(Math.random() * 4) + 1;
  return (
    <div className={GenericUtils.classNames('loading-view__root', props.className, props.position === 'absolute' ? 'loading-view__root--absolute' : undefined)}>
      <div
        style={{backgroundImage: `url(/img/loading/big-tits-loading-gif-${gif}.gif)`}}
        className="loading-view__image"
      />
      <div className="loading-view__hover">
        {props.message && <span className="loading-view__message">{props.message}</span>}
      </div>
    </div>
  );
}
