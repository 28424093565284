import * as React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Master from 'layouts/Master';
import useModelList from 'hooks/useModelList';
import ModelList from 'components/ModelList';
import LoadingView from 'components/LoadingView';
import Model from 'models/Model';
import PageUtils from 'utils/PageUtils';
import RouteUtils from 'utils/RouteUtils';

type Data = {
  pageData: {
    models: Model[];
    totalCount: number;
  };
};

type Props = {
  data: Data;
  pageContext: {
    category: string;
  };
};

export default function Category({ pageContext, data }: Props) {
  const { models, isLoading } = useModelList(data.pageData.models);
  const onlineModels = models.filter(m => m.isOnline === true);

  return (
    <Master
      title={PageUtils.generateTitle(`${pageContext.category} camgirls`)}
      description={`A curated list of busty ${pageContext.category} camgirls`}
    >
      <div className="container">
        <h1 className="page__title category__title">
          {pageContext.category} camgirls
        </h1>

        {
          isLoading ? <LoadingView position="relative" message={`Loading ${pageContext.category} camgirls`}/> : onlineModels.length > 0
            ? <ModelList models={onlineModels} /> : <p>Our {pageContext.category}'s camgirls are currently offline, check out other <a href={RouteUtils.toCategories()}>categories</a></p>
        }
      </div>
    </Master>
  );
}

export const pageQuery = graphql`
  query CategoryPage($category: String!) {
    pageData: allCamGirl(filter: {tags: {in: [$category]}}) {
      models: nodes {
        username
        tags
        id
        followersCount
      }
      totalCount
    }
  }
`;
